import Image from "next/image";
import Link from "next/link";
import React from "react";
import heroSloth from "../../../public/images/hero-compressed-2.jpeg";
function Hero() {
  return (
    <>
      <div className="flex lg:flex-row flex-col items-center justify-around bg-[#F4F8FF] h-auto w-screen py-8 px-4">
        <div className=" space-y-8 py-8 w-full lg:w-[50%] px-2 lg:px-28 flex flex-col items-start justify-center">
          {/* typography */}

          <h1 className="text-[#294870] text-6xl lg:text-7xl font-bold">
            Brighten up <br /> their day
          </h1>
          <p className="text-sm w-[90%] lg:w-[87%]">
            With our stylish gift boxes, and sloth themed products you can
            choose a range of goodies to put a smile on that special
            someone&apos;s face. Sloths may be slow, but our delivery
            isn&apos;t! (Available with fast UK delivery.)
          </p>
          <Link href="/products">
            <button className="font-medium hover:bg-[#355b8d] bg-[#294870] text-white px-8 py-2 rounded-[31px]">
              View gifts
            </button>
          </Link>
          <h2 className="text-[#294870] font-semibold w-[70%] lg:w-[45%]">
            Send a Sloth is the ultimate gift idea to bring happiness to
            someone&apos;s world.
          </h2>

          {/* socila emdia share */}
          <div className="flex items-center space-x-4">
            <a href="https://www.facebook.com/sendasloth">
              <div className="cursor-pointer flex items-center space-x-2">
                <svg
                  width={20}
                  height={21}
                  viewBox="0 0 26 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.6933 0.241211H5.17333C2.31618 0.241211 0 2.55739 0 5.41454V20.9345C0 23.7916 2.31618 26.1078 5.17333 26.1078H10.9933V18.3479H6.46666V14.4679H10.9933V11.8812C10.9933 9.02405 13.3095 6.70787 16.1666 6.70787H19.4V10.5879H16.1666C15.4523 10.5879 14.8733 11.1669 14.8733 11.8812V14.4679H19.4V18.3479H14.8733V26.1078H20.6933C23.5504 26.1078 25.8666 23.7916 25.8666 20.9345V5.41454C25.8666 2.55739 23.5504 0.241211 20.6933 0.241211Z"
                    fill="#294870"
                  />
                </svg>
              </div>
            </a>

            <a href="https://twitter.com/sendasloth">
              <div className="cursor-pointer flex items-center space-x-2">
                <svg
                  width={20}
                  height={17}
                  viewBox="0 0 25 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.9078 0.883057C14.1666 0.883057 11.9444 3.21643 11.9444 6.09478C11.9444 6.52973 11.9951 6.95223 12.0908 7.35613C9.45502 7.35613 5.48503 6.82323 2.16945 3.50144C1.72856 3.05972 0.91557 3.35506 0.946151 3.97839C1.43752 13.9941 5.72689 16.9155 7.93579 17.0973C6.50941 18.5061 4.43742 19.618 2.35245 20.1172C1.80219 20.2489 1.66626 20.8513 2.20432 21.0263C3.69622 21.5115 5.83163 21.6984 6.98107 21.73C15.1125 21.73 21.7215 14.8858 21.8686 6.38441C22.9362 5.69016 23.62 4.18273 24.0042 3.11486C24.0978 2.85478 23.6716 2.55176 23.4163 2.65799C22.6189 2.99 21.6064 3.06871 20.744 2.7877C19.8337 1.62477 18.4532 0.883057 16.9078 0.883057Z"
                    fill="#28303F"
                  />
                </svg>
              </div>
            </a>

            <a href="https://www.pinterest.co.uk/sendaslothuk/">
              <div className="cursor-pointer flex items-center space-x-2">
                <svg
                  width={21}
                  height={21}
                  viewBox="0 0 27 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M26.6782 13.1745C26.6782 20.3173 20.8877 26.1078 13.7448 26.1078C12.6699 26.1078 11.6256 25.9767 10.6271 25.7295L10.633 25.7079L12.0266 20.4115C12.5788 20.5421 13.1542 20.6112 13.7448 20.6112C17.8519 20.6112 21.1815 17.2816 21.1815 13.1745C21.1815 9.06737 17.8519 5.73787 13.7448 5.73787C9.63768 5.73787 6.30818 9.06737 6.30818 13.1745C6.30818 14.5275 6.67035 15.7987 7.30367 16.8935C7.57191 17.3573 8.16529 17.5157 8.62902 17.2475C9.09273 16.9793 9.25119 16.3859 8.98295 15.9222C8.51584 15.1147 8.24818 14.1774 8.24818 13.1745C8.24818 10.1388 10.7091 7.67787 13.7448 7.67787C16.7806 7.67787 19.2415 10.1388 19.2415 13.1745C19.2415 16.2102 16.7806 18.6712 13.7448 18.6712C13.3233 18.6712 12.9136 18.6239 12.5205 18.5346L14.036 12.7747C14.1723 12.2566 13.8628 11.7261 13.3447 11.5898C12.8267 11.4535 12.2962 11.763 12.1599 12.281L8.78142 25.1212C4.1018 23.1747 0.811523 18.5589 0.811523 13.1745C0.811523 6.03165 6.60196 0.241211 13.7448 0.241211C20.8877 0.241211 26.6782 6.03165 26.6782 13.1745Z"
                    fill="#28303F"
                  />
                </svg>
              </div>
            </a>
          </div>
        </div>
        <div className="lg:mr-14 flex items-center justify-center w-full lg:w-[50%]">
          <div className="w-full h-auto relative">
            <Image
              className="rounded-2xl"
              src={heroSloth}
              alt="hero section sloth image"
              loading="eager"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero;
