import React from "react";
import ProductCard from "../ProductCard";
import { useGiftBoxesCollection } from "@/hooks/collections";
import Link from "next/link";

function GiftProducts({ coll }) {
  const { data, isLoading } = useGiftBoxesCollection(coll);

  if (isLoading) "Loading...";

  return (
    <>
      <div className="bg-[#FCFCFC] flex items-center justify-center w-screen h-auto py-2">
        <div className="space-y-6 py-20 flex flex-col items-center justify-center  h-auto w-full lg:w-[90%]">
          <h1 className="text-[#294870] text-center text-4xl lg:text-4xl">
            Select Your {data?.title}
          </h1>
          <div className="space-y-6 lg:space-y-0 py-8 w-[100%] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-4 place-content-center place-items-center">
            {data?.products?.edges.map((product, key) => (
              <ProductCard
                key={key}
                title={product?.node?.title}
                desc={product?.node?.shortDescriptionMetafield?.value}
                price={product?.node?.priceRange?.maxVariantPrice?.amount}
                img={product?.node?.images?.edges[0]?.node?.originalSrc}
                handle={product?.node?.handle}
              />
            ))}
          </div>

          <div className="flex items-center justify-center w-full">
            {" "}
            <Link href="/products">
              <button className="font-medium hover:bg-[#355b8d] bg-[#294870] text-white px-8 py-3 rounded-[31px]">
                View More
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default GiftProducts;
