import React from "react";
import sloth1 from "../../../public/images/hero-compressed-2.jpeg";
import sloth2 from "../../../public/images/1-min.jpg";
import sloth3 from "../../../public/images/DSC_6662.jpg";
import GiftCard from "./GiftCard";

function GiftBoxes() {
  return (
    <>
      <div className="space-y-6 py-20 flex flex-col items-center justify-center bg-[#FCFCFC] h-auto w-screen">
        <h1 className="text-[#294870] text-center text-2xl lg:text-4xl">
          Choose your Gift(s)
          <br /> Here&apos;s How It Works:
        </h1>
        <div className="space-y-6 lg:space-y-0 py-8 w-screen flex lg:flex-row flex-col items-center justify-evenly">
          {/* card */}
          <GiftCard
            heading="SELECT THE PERFECT 
 GIFTS"
            desc="Pick from our adorable range of sloths to send to a loved one."
            img={sloth1}
          />
          <GiftCard
            heading="MAKE IT UNIQUE & PERSONAL"
            desc="Add a personalised message to your gift box and choose from our range of accessories to make it extra special."
            img={sloth2}
          />
          <GiftCard
            heading="SEND YOUR GIFT "
            desc="We aim to dispatch orders the following day to anywhere you choose from our London slothquarters"
            img={sloth3}
          />
          {/* card */}
        </div>
      </div>
    </>
  );
}

export default GiftBoxes;
