import React from "react";
import Image from "next/image";
const GiftCard = ({ heading, desc, img }) => {
  return (
    <>
      <div className="bg-[#F7F7F7] h-auto lg:h-[64vh] w-[80%] lg:w-[25%] rounded-[15px]">
        <div>
          <Image src={img} alt="sloth1" />
        </div>
        <div className="space-y-2  px-6 py-4">
          <h1 className="text-[#294870] text-xl">{heading}</h1>
          <p>{desc}</p>
        </div>
      </div>
    </>
  );
};

export default GiftCard;
