import Carousel from "@/components/carousel/Carousel";
import Footer from "@/components/Footer";
import Blogs from "@/components/Home/Blogs";
import Delivery from "@/components/Home/Delivery";
import GiftBoxes from "@/components/Home/GiftBoxes";
import GiftProducts from "@/components/Home/GiftProducts";
import Hero from "@/components/Home/Hero";
import Layout from "@/components/Layout";

export default function Home() {
  return (
    <>
      <Hero />

      <GiftBoxes />
      <GiftProducts coll={"gift-boxes"} />
      <GiftProducts coll={"accessories"} />

      <div className="flex items-center justify-center bg-[#FCFCFC] w-full h-auto py-8">
        <div className=" bg-[#F7F7F7]  w-[90%] h-auto">
          <Carousel />
        </div>
      </div>
      <Delivery />
      <div className="bg-[#FCFCFC] w-screen h-[100vh] lg:h-[40vh]"></div>
      {/* <Layout>
        <Blogs />
      </Layout> */}
      <Footer />
    </>
  );
}
