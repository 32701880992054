import Image from "next/image";
import React from "react";
import loadingImage from "../../public/images/slothLoading.gif";
const Loading = () => {
  return (
    <div className="left-0 flex flex-col items-center justify-center z-50 bg-white h-screen w-screen fixed top-0">
      <div className="w-[50%] lg:w-[30%]">
        <Image src={loadingImage} />
      </div>

      <h1 className="text-4xl font-bold text-[#294870]">Loading...</h1>
    </div>
  );
};

export default Loading;
