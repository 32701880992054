import React, { useEffect } from "react";
import { useBlogs } from "@/hooks/blogs";
import BlogCard from "../BlogCard";
import Loading from "../Loading";
import Link from "next/link";

function Blogs() {
  const { blogArticles, isLoading } = useBlogs();

  if (isLoading) return "Loading...";

  return (
    <>
      <div className="flex items-center justify-center w-full h-auto py-4 bg-[#FCFCFC]">
        <div className="w-full flex items-center justify-center flex-col">
          <div className="flex items-center justify-between  w-full h-[10vh]">
            <h1 className="text-4xl lg:text-5xl text-[#294870] ">Sloth News</h1>
            <Link href="/blog">
              <button className="font-medium hover:bg-[#355b8d] bg-[#294870] text-white px-6 py-1 lg:px-8 lg:py-3 rounded-[31px]">
                View all
              </button>
            </Link>
          </div>

          <div className="space-y-6 lg:space-y-0 py-8 w-full flex lg:flex-row flex-col items-center justify-between">
            {blogArticles.slice(0, 3).map((article, key) => (
              <BlogCard
                key={key}
                heading={article.title}
                date={article.publishedAt}
                img={article.image.originalSrc}
                link={`/blog/${article.handle}`}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Blogs;
