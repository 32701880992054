import { storefront } from "@/helpers/store";
import { GiftBoxesCollection } from "@/helpers/queries";
import React, { useState, useEffect } from "react";

export function useGiftBoxesCollection(coll) {
  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const Collection = `
query {
  collectionByHandle(handle: "${coll}") {
    id
    title
    description
    products(first: 250) {
      edges {
        node {
          id
          title
          handle
          description
          descriptionHtml
          addonsMetafield: metafield(namespace: "custom", key: "addons") {
            description
            value
          }
          shortDescriptionMetafield: metafield(namespace: "custom", key: "short_description") {
            description
            value
          }
          productDetailsMetafield: metafield(namespace: "custom", key: "product_details") {
            description
            value
          }
          priceRange {
            minVariantPrice {
              amount
              currencyCode
            }
            maxVariantPrice {
              amount
              currencyCode
            }
          }
          compareAtPriceRange {
            minVariantPrice {
              amount
              currencyCode
            }
            maxVariantPrice {
              amount
              currencyCode
            }
          }
          totalInventory
          images(first: 6) {
            edges {
              node {
                id
                originalSrc
                altText
              }
            }
          }
          variants(first: 250) {
            edges {
              node {
                id
                title
                price {
                  amount
                  currencyCode
                }
                sku
                selectedOptions {
                  name
                  value
                }
              }
            }
          }
          tags
          # Add any other fields you need here
        }
      }
    }
  }
}
`;

  const fetchCollection = async () => {
    setLoading(true);
    try {
      const response = await storefront(Collection);
      if (response.errors) {
        // Handle any errors returned by the API
        console.error(response.errors);
        setData(null);
      } else {
        const collection = response.data?.collectionByHandle;
        setData(collection);
      }
    } catch (error) {
      // Handle any network or parsing errors
      console.error(error);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCollection();
  }, []); // Empty dependency array to trigger the effect only once

  return { data, isLoading };
}
