import Image from "next/image";
import React from "react";
import reviewImage from "../../../public/images/reviewimage.png";
const CarouselCard = ({ reviewItem }) => {
  const { title, name, totalReviews, date, review } = reviewItem;
  return (
    <>
      <div className=" space-y-4 px-4 py-4 min-w-[100%] bg-transparent max-w-[100%] lg:min-w-[300px] lg:max-w-[300px]  h-[320px] md:border-r border-[#D9DADB] m-[10px]">
        <div className="w-full flex items-center justify-between">
          <h2 className="w-[80%]">{title}</h2>
        </div>
        {/* stars */}
        <div className="flex items-center space-x-2">
          <svg
            width={103}
            height={19}
            viewBox="0 0 103 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M96.4539 6.46968L103 7.41428L98.2633 11.9995L99.3815 18.4738L93.5264 15.4171L87.6714 18.4738L88.7896 11.9995L84.0527 7.41428L90.5989 6.46968L93.5264 0.579102L96.4539 6.46968Z"
              fill="#FFA34D"
            />
            <path
              d="M93.5264 0.579102V15.4171L87.6714 18.4738L88.7896 11.9995L84.0527 7.41428L90.5989 6.46968L93.5264 0.579102Z"
              fill="#FFA34D"
            />
            <path
              d="M75.4539 6.46968L82.0001 7.41428L77.2633 11.9995L78.3815 18.4738L72.5264 15.4171L66.6714 18.4738L67.7896 11.9995L63.0527 7.41428L69.5989 6.46968L72.5264 0.579102L75.4539 6.46968Z"
              fill="#FFA34D"
            />
            <path
              d="M54.4539 6.46968L61.0001 7.41428L56.2633 11.9995L57.3815 18.4738L51.5264 15.4171L45.6714 18.4738L46.7896 11.9995L42.0527 7.41428L48.5989 6.46968L51.5264 0.579102L54.4539 6.46968Z"
              fill="#FFA34D"
            />
            <path
              d="M33.4539 6.46968L40.0001 7.41428L35.2633 11.9995L36.3815 18.4738L30.5264 15.4171L24.6714 18.4738L25.7896 11.9995L21.0527 7.41428L27.5989 6.46968L30.5264 0.579102L33.4539 6.46968Z"
              fill="#FFA34D"
            />
            <path
              d="M12.4539 6.46968L19.0001 7.41428L14.2633 11.9995L15.3815 18.4738L9.52642 15.4171L3.67136 18.4738L4.78958 11.9995L0.0527344 7.41428L6.59889 6.46968L9.52642 0.579102L12.4539 6.46968Z"
              fill="#FFA34D"
            />
          </svg>
          {/* <p className="text-xs text-[#939699]">{totalReviews} Reviews</p> */}
        </div>
        {/* review */}
        <p className="text-xs">{review.slice(0, 307)}</p>
        {/* name */}
        <h4 className="text-sm text-[#294870]">{name}</h4>
        {/* date */}
        <p className="text-xs text-gay-300">{date}</p>
      </div>
    </>
  );
};

export default CarouselCard;
