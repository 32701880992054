import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, A11y } from "swiper";
import CarouselCard from "./CarouselCard";

// Install Swiper modules
SwiperCore.use([Navigation, Pagination, A11y]);

// JSON dummy data for reviews

const REVIEWS = [
  {
    title: "My neice was really pleased",
    totalReviews: 102,
    review:
      "My neice was really pleased with her sloth! And the extra touches of card, sticker and sweets was amazing!!!",
    name: "Susan",
    date: "07 February",
  },
  {
    title: "My step daughter found this site",
    totalReviews: 16,
    review:
      "My step daughter found this site all by herself and she ordered a lobster because that’s what her and her brother call him. He is gorgeous and hasn’t been let go of!! The sloth is gorgeous too and has been named gruff after the super furry animals!! Seriously though what an amazing idea and such beautiful products!! The staff are so kind and deserve your business 😊 definitely going to be recommending these 😄",
    name: "Twinklor",
    date: "23 June",
  },
  {
    title: "Lovely sloth 🦥",
    totalReviews: 43,
    review:
      "Lovely sloth 🦥 my grandson loved it well stuffed but still cuddly and very soft great to add to his growing collection!!!",
    name: "Julie",
    date: "25 December",
  },
  {
    title: "My daughter loves it",
    totalReviews: 9,
    review:
      "My daughter absolutely loved it. I'm currently in hospital so I sent it to her to cheer her up. She cuddles it all the time. Would buy again.",
    name: "Claire",
    date: "07 February",
  },
  {
    title: "I was extremely ill in hospital",
    totalReviews: 102,
    review:
      "I was extremely ill in hospital and my better half Melissa was running around,helping run my pub,whilst she was doing her job as a mental health nurse,plus worrying about me and my health. I saw send a sloth as she loves them,so I sent her one to show my love and appreciation for her. Thank you very much.",
    name: "Jason",
    date: "20 July",
  },
  {
    title: "Amazing value",
    totalReviews: 16,
    review:
      "Fantastic service and plenty of communication about delivery, which makes a change as not a lot of businesses keep you involved. the sloth is adorable and so soft. He looks so cute in his little hat. Good price and excellent quality.Definitely recommend to a friend.",
    name: "Lisa-anne",
    date: "10 November",
  },
  {
    title: "Fantastic company",
    totalReviews: 43,
    review:
      "I was sent a sloth. Wasn't quite a surprise as the sender couldn't keep it a secret 😆 lovely fast delivery and well presented. The sloth is also beautifully soft. Just bear in mind that the box definitely isn't inconspicuous as it has branded tape all the way round it.",
    name: "Daryl",
    date: "21 December",
  },
  {
    title: "An amazing company with amazing sloths!",
    totalReviews: 9,
    review:
      "Such a brilliant company! I had a very slim delivery window and they went above and beyond to make sure it arrived on time for my friend's birthday! She loves her sloth! The communication with customer services was excellent. Such a great gift and so much thought goes into each one - thank you!",
    name: "Niamh",
    date: "26 August",
  },
];

function Carousel() {
  const [swiper, setSwiper] = useState(null);

  const handleSlidePrev = () => {
    if (swiper) {
      swiper.slidePrev();
    }
  };

  const handleSlideNext = () => {
    if (swiper) {
      swiper.slideNext();
    }
  };

  const onSwiper = (swiperInstance) => {
    setSwiper(swiperInstance);
  };

  return (
    <>
      <div className=" flex items-center justify-between w-full h-auto py-4 px-4 bg-[#F7F7F7] border-b-[2px] border-[#D9DADB]">
        <h3 className="text-[#294870] text-2xl">What people say</h3>
        <div className="flex items-center">
          <button
            className="focus:bg-[#D9DADB] border-[2px] border-[#D9DADB] rounded-tl-[4px] rounded-bl-[4px] py-2 px-3"
            onClick={handleSlidePrev}
          >
            <svg
              width={15}
              height={18}
              viewBox="0 0 15 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 1.10194V16.8982C15 17.5067 14.4893 18 13.8594 18C13.6407 18 13.4266 17.9393 13.2426 17.825L0.523955 9.92688C-0.00593758 9.59783 -0.159353 8.91612 0.181293 8.40426C0.26965 8.27149 0.386509 8.1586 0.523955 8.07325L13.2426 0.175125C13.7725 -0.153933 14.4782 -0.0057354 14.8188 0.50613C14.9371 0.683849 15 0.890669 15 1.10194Z"
                fill="#294870"
              />
            </svg>
          </button>
          <button
            className="focus:bg-[#D9DADB] border-[2px] border-[#D9DADB] rounded-tr-[4px] rounded-br-[4px] py-2 px-3"
            onClick={handleSlideNext}
          >
            <svg
              width={15}
              height={18}
              viewBox="0 0 15 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 16.8981L0 1.10181C0 0.493296 0.510668 0 1.14061 0C1.35932 0 1.57343 0.0607431 1.7574 0.174991L14.476 8.07312C15.0059 8.40217 15.1594 9.08388 14.8187 9.59574C14.7303 9.72851 14.6135 9.8414 14.476 9.92675L1.7574 17.8249C1.22751 18.1539 0.521799 18.0057 0.181153 17.4939C0.0628822 17.3162 0 17.1093 0 16.8981Z"
                fill="#294870"
              />
            </svg>
          </button>
        </div>
      </div>
      <Swiper
        modules={[Navigation, Pagination, A11y]}
        slidesPerView="auto"
        breakpoints={{
          640: {
            slidesPerView: 1,
          },

          768: {
            slidesPerView: 2,
          },

          1024: {
            slidesPerView: 3,
          },
          1280: {
            slidesPerView: 4,
          },
        }}
        onSwiper={onSwiper}
        onSlideChange={() => {}}
      >
        {REVIEWS.map((reviewItem, key) => (
          <SwiperSlide key={key}>
            <CarouselCard reviewItem={reviewItem} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}

export default Carousel;
