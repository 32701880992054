import React from "react";
import Image from "next/image";
import Link from "next/link";
const BlogCard = ({ heading, date, img, smallSize, link }) => {
  return (
    <>
      <Link href={link}>
        <div
          className={`flex flex-col items-center justify-center bg-[#F7F7F7] h-auto py-4 w-[80%] lg:w-[345px] rounded-[15px]`}
        >
          <div className="relative w-[80%] h-[250px] px-4">
            <Image className="rounded-3xl" src={img} alt={heading} fill />
          </div>
          <div className="space-y-3  px-6 py-4">
            <h1 className="text-[#294870] text-2xl">
              {heading.slice(0, 39)}...
            </h1>
            <p className="font-light text-[#1E1E1E]">{date.slice(0, 10)}</p>
            <p className="text-[#294870]  cursor-pointer hover:underline">
              Read More
            </p>
          </div>
        </div>
      </Link>
    </>
  );
};

export default BlogCard;
