import React from "react";
import Image from "next/image";
import Link from "next/link";

function ProductCard({ title, desc = " ", handle, img, price }) {
  return (
    <>
      <Link href={`/products/${handle}`}>
        <div className="flex flex-col justify-between items-center bg-white border border-gray-300 w-full  lg:w-[20vw] h-[60vh] rounded-[15px]">
          <div className="space-y-2  cursor-pointer  w-full h-full flex flex-col items-center justify-center">
            <div className=" w-[80%] h-auto">
              <Image
                className="rounded-lg hover:scale-95 transition"
                src={img}
                alt={title}
                width={400}
                height={250}
              />
            </div>
            <h1 className="text-[#294870] text-2xl text-center ">{title}</h1>
            <p className="text-center text-sm px-2 pt-1">{desc} </p>
          </div>
          <div className="flex items-center justify-evenly  w-full h-[15%] border-t border-gray-200">
            <p>&pound;{parseFloat(price).toFixed(2)}</p>
            <div className="bg-gray-300 h-[60%] w-[2px]"></div>
            <p className="text-[#294870] cursor-pointer hover:underline">
              Shop Now
            </p>
          </div>
        </div>
      </Link>
    </>
  );
}

export default ProductCard;
