import Image from "next/image";
import React from "react";

import slothDelivery from "../../../public/images/delivery.webp";
import backgroundSloth from "../../../public/images/backgroundsloth.png";
import Link from "next/link";
function Delivery() {
  return (
    <>
      <div className="relative flex lg:flex-row-reverse flex-col items-start justify-between bg-[#F4F8FF] h-auto lg:h-[120vh] w-screen py-28 lg:py-20 px-4">
        <div className=" space-y-6 pb-8 w-full lg:w-[60%] px-2 lg:px-28 flex flex-col items-start justify-center">
          {/* typography */}
          <h1 className="text-[#294870] text-6xl lg:text-6xl font-bold">
            How long does <br /> delivery take?
          </h1>
          <h2 className="font-medium text-[#294870]  w-[70%] lg:w-[45%]">
            These Sloths aren&apos;t slow!
          </h2>
          <p className="font-light w-[90%] lg:w-[87%]">
            All our sloth gifts are dispatched from Sloth HQ here in London, UK
            via Royal Mail.
            <br />
            <br />
            We aim to deliver within 2-4 working days, with a next day service
            available at checkout if your gifts are needed in a hurry!
          </p>
          <Link href="/contact-us">
            <button className="font-medium hover:bg-[#355b8d] bg-[#294870] text-white px-10 py-2 rounded-[31px]">
              Contact us
            </button>
          </Link>
        </div>
        <div className="pb-24  lg:pb-2 flex items-center justify-center w-full lg:w-[50%]">
          <div className="w-full h-auto relative">
            <Image className="rounded-2xl" src={slothDelivery} />
          </div>
        </div>

        {/* overlay */}
        <div className=" left-0 absolute flex items-center justify-center  -bottom-[50%] lg:-bottom-[15%] w-full h-auto py-2">
          <div className="relative  flex lg:flex-row flex-col items-center lg:items-start justify-around bottom-0 w-[80%] lg:h-auto h-[100vh] py-12 bg-[#294870] rounded-[15px]">
            <div className="overflow-hidden z-30 w-[80%] lg:w-[25%] -bottom-[65%] lg:top-0 h-full absolute lg:right-0">
              <Image src={backgroundSloth} alt="overlay sloth image" />
            </div>

            <div className="text-white flex items-center justify-center flex-col space-y-3">
              <h2 className="text-6xl  font-semibold">3+</h2>
              <p className="text-center">
                Pioneers in sloth-
                <br />
                based smiles for over <br />
                three years.
              </p>
            </div>
            <div className="text-white flex items-center justify-center flex-col space-y-3">
              <h2 className="text-6xl  font-semibold">6000+</h2>
              <p>Sloths Delivered</p>
            </div>
            <div className="z-40 text-white flex items-center justify-center flex-col space-y-3">
              <h2 className="text-6xl  font-semibold">8000+</h2>
              <p>Days Brightened</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Delivery;
