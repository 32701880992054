import { storefront } from "@/helpers/store";
import {
  BlogQuery,
  productsQuery,
  SingleArticleQuery,
} from "@/helpers/queries";
import React, { useState, useEffect } from "react";

// Fetch all the articles from news blog
export function useBlogs() {
  const [blogArticles, SetBlogArticles] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const fetchBlogArticles = async () => {
    setLoading(true);
    try {
      const response = await storefront(BlogQuery);
      if (response.errors) {
        // Handle any errors returned by the API
        console.error(response.errors);
        SetBlogArticles([]);
      } else {
        const articles = response.data.blogByHandle.articles.edges.map(
          ({ node }) => node
        );
        SetBlogArticles(articles);
      }
    } catch (error) {
      // Handle any network or parsing errors
      console.error(error);
      SetBlogArticles([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBlogArticles();
  }, []);

  return { blogArticles, isLoading };
}

// Fetch all the articles from news blog
export function useSingleArticle(title) {
  const [article, setArticle] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const fetchSingleArticle = async () => {
    setLoading(true);

    try {
      const response = await storefront(`
      query {
        blogByHandle(handle: "news") {
          articles(first: 1, query: "${title}") {
            edges {
              node {
                id
                title
                contentHtml
                excerptHtml
                publishedAt
                blog {
                  handle
                }
                image {
                  id
                  originalSrc
                  altText
                }
              }
            }
          }
        }
      }
      
      `);
      if (response.errors) {
        // Handle any errors returned by the API
        console.error(response.errors);
        setArticle([]);
      } else {
        const article = response.data.blogByHandle.articles.edges[0].node;

        setArticle(article);
      }
    } catch (error) {
      // Handle any network or parsing errors
      console.error(error);
      setArticle([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSingleArticle();
  }, [title]);

  return { article, isLoading };
}
